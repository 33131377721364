<template>
  <div class="wrap">
		<div class="title" v-html="info.title"></div>
		<div class="content">
			<div class="tip" v-html="info.content"></div>
		</div>

		<!-- 参考文献 -->
		<Reference :info="refInfo"></Reference>
	</div>
</template>

<script>
import { Toast } from 'vant'
import Reference from '../../components/Reference'
import { getAssessToolDetail } from '../../common/api/index'
export default {
  data() {
    return {
			id: '',
			type: 0,
			info: {},
			refInfo: ""
		};
  },
	components: {
		Reference
	},
  created() {
		this.id = this.$route.query.id
		this.categoryId = this.$route.query.categoryId
		this.findDetail()

		// 访问记录
		this.$Common._record({openid: this.$store.state.openid, module_id: 4, category_id: this.categoryId, article_id: this.id, page: this.$route.path})
	},
  mounted() {
		this.$wxShare._wxConfigJSSDK()
	},
  methods: {
		/**
		 * 获取病例详情
		 */
		findDetail () {
			Toast.loading({
        message: '',
        forbidClick: true,
        duration: 0
      })
			let that = this
			getAssessToolDetail({id: that.id}).then(res => {
				Toast.clear()
				// console.log(res)
				if (res.code === 200) {
					that.info = res.data.assess_tool_detail
					that.refInfo = res.data.assess_tool_detail.reference
				} else {
					Toast(res.msg)
				}
			})
		}
	}
};
</script>

<style scoped lang="scss">
.title{
	width: 70%;
	font-size: 40px;
	text-align: center;
	margin: auto;
	margin-top: 94px;
	position: relative;

	&::before{
		content: '';
		width: 100%;
		height: 4px;
		background: url('../../assets/img/title_line.png') top center no-repeat;
		background-size: 100% 100%;
		position: absolute;
		bottom: 0;
		left: 0;
	}
}
.content{
	width: 85%;
	margin: auto;
	// margin-top: 60px;
	box-sizing: border-box;
	padding: 50px 0;
	position: relative;
	font-size: 26px;

	/deep/ li{
		margin-top: 20px;
		list-style: none;

		p{
			font-weight: bold;
			color: #49FCFC;
			font-size: 28px;
			display: flex;
			align-items: center;

			&:before{
				width: 10px;
				height: 10px;
				border-radius: 50%;
				content: '';
				background: #49FCFC;
				margin-right: 20px;
			}
		}
	}

	/deep/ li:first-child{
		margin-top: 0;
	}

	.tip{
		/deep/p{
			font-weight: bold;
			color: #49FCFC;
			font-size: 28px;
		}
	}

	.icon-left{
		width: 35px;
		position: absolute;
		top: -20px;
		left: 57px;
		z-index: 2;
	}

	.icon-right{
		width: 35px;
		position: absolute;
		top: -20px;
		right: 81px;
		z-index: 2;
	}
	.tip{
		position: relative;
		z-index: 2;
	}
}
/deep/ sup{
	margin-top: 10px;
}
</style>
